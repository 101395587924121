import * as React from 'react';
import { Button, Divider, Grid, Typography } from 'src/app/components';
import { SourceCollectionName } from 'src/app/constants';
import {
  useAppContext,
  // source data
  useSourcedCategories,
  useSourcedCategoryTypes,
  useSourcedCities,
  useSourcedCityAreas,
  useSourcedContinents,
  useSourcedCountries,
  useSourcedCountryAreas,
  useSourcedCurrencies,
  useSourcedLocales,
  useSourcedLocations,
  useSourcedProducts,
  useSourcedTags,
} from 'src/app/hooks';
import { getSystemSourceCollectionPath } from 'src/lib/db';

export const SourcedDataTest = ({
  collectionName,
}: {
  collectionName: SourceCollectionName;
}) => {
  const { firebase, isLoggedIn } = useAppContext();
  const categories = useSourcedCategories();
  const categoryTypes = useSourcedCategoryTypes();
  const cities = useSourcedCities(); // + city area
  const cityAreas = useSourcedCityAreas();
  const continents = useSourcedContinents();
  const countries = useSourcedCountries(); // + country area
  const countryAreas = useSourcedCountryAreas();
  const currencies = useSourcedCurrencies();
  const locales = useSourcedLocales();
  const locations = useSourcedLocations();
  const products = useSourcedProducts();
  const tags = useSourcedTags();
  // TODO: make propnames static (also in gatsby)
  const sourcedData = {
    category: categories,
    'category-type': categoryTypes,
    city: cities,
    'city-area': cityAreas,
    continent: continents,
    country: countries,
    'country-area': countryAreas,
    currency: currencies,
    locale: locales,
    location: locations,
    product: products,
    tag: tags,
  };

  const [results, setResults] = React.useState<any[]>([]);

  const onClick = () => {
    if (!firebase) {
      return;
    }
    firebase
      .firestore()
      .collection(getSystemSourceCollectionPath(collectionName))
      .get()
      .then((res) => {
        console.log('Got res: ', res);
        if (!res.empty) {
          setResults([...res.docs.filter((doc) => doc.exists).map((doc) => doc.data())]);
        }
      });
  };

  let compareSourceValues = [];
  // case SourcedCollectionName.CATEGORY_TYPE:
  switch (collectionName) {
    case SourceCollectionName.CATEGORY:
    case SourceCollectionName.CATEGORY_TYPE:
    case SourceCollectionName.CITY:
    case SourceCollectionName.CITY_AREA:
    case SourceCollectionName.CONTINENT:
    case SourceCollectionName.COUNTRY:
    case SourceCollectionName.COUNTRY_AREA:
    case SourceCollectionName.CURRENCY:
    case SourceCollectionName.LOCALE:
    case SourceCollectionName.LOCATION:
    case SourceCollectionName.PRODUCT:
    case SourceCollectionName.TAG:
      compareSourceValues = sourcedData[collectionName];
      break;
    default:
      compareSourceValues = [];
      break;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>Datatest {collectionName}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Database data</Typography>
          </Grid>
          {results?.map((doc: { id: string }) => (
            <Grid key={doc.id} item xs={12}>
              {doc.id}
            </Grid>
          )) || null}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Sourced data</Typography>
          </Grid>
          {compareSourceValues?.map((doc: { id: string }) => (
            <Grid key={doc.id} item xs={12}>
              {doc.id}
            </Grid>
          )) || null}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onClick}>Update</Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};
