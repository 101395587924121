import * as React from 'react';
import { Container, Grid, Typography } from 'src/app/components';
import { AppPage } from 'src/app.consumer/components';
import { SourceCollectionName } from 'src/app/constants';
import { SourcedDataTest } from 'src/app.x/components/SourcedDataTest';

export default (props: any) => {
  // const { children, location, pageContext, seo, ...passProps } = props;
  const { children, seo, ...passProps } = props;
  // Make Guard wrapper for pages which wont be rendered (and/or filter these pages out on build?
  if (
    typeof window === 'undefined' ||
    (window.location.hostname !== 'localhost' &&
      window.location.hostname !== 'travlr-dev.netlify.app') // TODO: .env
  ) {
    return null;
  }

  return (
    <AppPage seo={seo} {...passProps}>
      <Container>
        {/* <Seo location={location} pageContext={pageContext} seo={seo} /> */}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography align={'center'} variant={'h4'} renderAs={'h1'}>
              Source Data tests
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SourcedDataTest collectionName={SourceCollectionName.CATEGORY} />
          </Grid>
          <Grid item xs={12}>
            <SourcedDataTest collectionName={SourceCollectionName.CATEGORY_TYPE} />
          </Grid>
          <Grid item xs={12}>
            <SourcedDataTest collectionName={SourceCollectionName.CITY} />
          </Grid>
          <Grid item xs={12}>
            <SourcedDataTest collectionName={SourceCollectionName.CITY_AREA} />
          </Grid>
          <Grid item xs={12}>
            <SourcedDataTest collectionName={SourceCollectionName.CONTINENT} />
          </Grid>
          <Grid item xs={12}>
            <SourcedDataTest collectionName={SourceCollectionName.COUNTRY} />
          </Grid>
          <Grid item xs={12}>
            <SourcedDataTest collectionName={SourceCollectionName.COUNTRY_AREA} />
          </Grid>
          <Grid item xs={12}>
            <SourcedDataTest collectionName={SourceCollectionName.CURRENCY} />
          </Grid>
          <Grid item xs={12}>
            <SourcedDataTest collectionName={SourceCollectionName.LOCALE} />
          </Grid>
          <Grid item xs={12}>
            <SourcedDataTest collectionName={SourceCollectionName.LOCATION} />
          </Grid>
          <Grid item xs={12}>
            <SourcedDataTest collectionName={SourceCollectionName.PRODUCT} />
          </Grid>
          <Grid item xs={12}>
            <SourcedDataTest collectionName={SourceCollectionName.TAG} />
          </Grid>
        </Grid>
      </Container>
    </AppPage>
  );
};
